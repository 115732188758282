import { alpha, fontSize, keyframes } from "@mui/system";
import { createTheme } from "@mui/material/styles";

let waveKeyframes = keyframes` 0% {
  transform: translateX(-100%);
}
35% {
  transform: translateX(-100%);
}
65% {
  /* +0.5s of delay between each loop */
  transform: translateX(100%);
}
100% {
  transform: translateX(100%);
}`;
let kfc = keyframes` 0% {
  transform: translateX(-60%);
}
35% {
  transform: translateX(-60%);
}
65% {
  /* +0.5s of delay between each loop */
  transform: translateX(60%);
}
100% {
  transform: translateX(60%);
}`;

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#d31820",
      dark: "#d31820",
      light: "#9e1218",
    },
    secondary: {
      main: "#ffffff",
      contrastText: "#020202",
      dark: "#ffffff",
      light: "#F2F2F2",
    },
    background: {
      default: "#ffffff",
    },
    divider: "#C0C0C0",
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
  components: {
    MuiSkeleton: {
      styleOverrides: {
        text: {
          borderRadius: 4,
        },
        root: ({ ownerState, theme }) => ({
          position: "relative",
          overflow: "hidden",
          ...(ownerState.animation === "wave" && {
            "&:after": {
              animation: `${waveKeyframes} 2.2s linear 0.0s infinite alternate-reverse`,
              background:
                "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 47.63%, rgba(0, 0, 0, 0) 100%)",
              position: "absolute",
              transform:
                "translateX(-100%)" /* Avoid flash during server-side hydration */,
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            },
          }),
          ...(ownerState.variant === "circular" && {
            "&:after": {
              background:
              "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 45%,rgba(0, 0, 0, 0.2) 55%, rgba(0, 0, 0, 0) 100%)",
              animation: `${waveKeyframes} 2.2s linear 0.0s infinite alternate-reverse`,
            },
          }),
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: theme.shape.borderRadius,
          "&:hover": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              theme.palette.action.hoverOpacity
            ),
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.background.default,
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root:{
          minHeight:"40px"
        },
        indicator: {
          visibility: "hidden",
        },
        flexContainer: {
          justifyItems: "flex-end",
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: "none",
          borderRadius: theme.shape.borderRadius,
          padding: "0px 24px",
          marginRight: "8px",
          height: "40px",
          minHeight: "40px",
          ...(ownerState.textColor === "primary" && {
            ...(ownerState.selected && {
              border: `1px solid ${theme.palette.primary.main}`,
              backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.hoverOpacity
              ),
            }),
            "&:hover": {
              backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.hoverOpacity
              ),
              color: theme.palette.primary.main,
            },
          }),
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height:"48px",
          textTransform: "none",
          ...(ownerState.variant === "outlined" && {
            backgroundColor: alpha(
              theme.palette.text.primary,
              theme.palette.action.hoverOpacity
            ),
          }),
          ...(ownerState.variant === "text" && {
            padding: "5px 15px",
          }),
        }),
      },
    },
  },

  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontSize: 49,
      fontWeight: 700,
    },
    fontFamily: "Montserrat",
    h2: {
      fontSize: 39,
      fontWeight: 700,
    },
    h3: {
      fontSize: 31,
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
    h4: {
      fontSize: 25,
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
    subtitle1: {
      fontFamily: "Nunito Sans",
      fontSize: 20,
    },
    body1: {
      fontFamily: "Nunito Sans",
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontFamily: "Nunito Sans",
      fontSize: 16,
      fontWeight: 700,
    },
    caption: {
      fontSize: 13,
    },
    overline: {
      fontWeight: 700,
      fontSize: 10,
    },
    button: {
      fontFamily: "Nunito Sans",
      fontSize: 16,
      fontWeight: 700,
    },
  },
});

export default lightTheme;
