import CompanyDashboard from "./CompanyDashboard";
export default CompanyDashboard

type CompanyDashboardProps = {
    loading:boolean
}

export type {
    CompanyDashboardProps
}
