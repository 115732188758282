import { Grid, Typography, Button, SvgIcon } from "@mui/material";
import lock from "../../resources/images/lock.png";
import { ReactComponent as BackArrowIcon } from "../../resources/icons/arrow-left.svg";
import { ErrorType } from "../../App";

export default function ErrorPage({ errorType }: { errorType: ErrorType }) {
  return (
    <Grid
      height="100vh"
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid position="absolute">
        <Typography
          fontSize="400px"
          fontFamily="Montserrat"
          color="#f2f2f2"
          fontWeight="700"
        >
          {errorType === "Unauthorized" ? "403" : "Oops"}
        </Typography>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        position="absolute"
      >
        <Grid item>
          <img src={lock} alt="unauthorized" />
        </Grid>
        <Grid item>
          <Typography variant="h1">
            {errorType === "Unauthorized"
              ? "Access Denied"
              : "Something Went Wrong"}
          </Typography>
        </Grid>
        <Grid item paddingTop="12px" width="400px" textAlign="center">
          <Typography variant="subtitle2" color="#747474">
            {errorType === "Unauthorized"
              ? `You don’t have permission to access this page. If you believe this
            is a mistake, please contact the owner.`
              : "An unexpected error ocurred, please contact the owner"}
          </Typography>
        </Grid>
        <Grid item paddingTop="28px">
          <Button variant="contained" onClick={() => {}}>
            <Grid container alignItems="center">
              <SvgIcon component={BackArrowIcon} fontSize="small" />
              <Grid item>Go back</Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
