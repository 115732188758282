import {
  Button,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  ListItemButton,
  OutlinedInput,
  Paper,
  Popper,
  Radio,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useJaqlQueryAPI from "../../hooks/JaqlQueryAPI";
import { DateTimeFilterProps } from "./";
import { DateTimeLevel } from "../EmbedSDK";
import { AppContext } from "../../context";
import  { DateTimeFrame } from "../../hooks/DateTimeFilter";
import useTimeFrames from "../../hooks/useTimeFrames"

export default function DateTimeFilterX({
  jaql,
  label,
  onSelectionChange,
  onOpen,
  onClose
}: DateTimeFilterProps) {
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const anchorRef = React.useRef<HTMLDivElement | null>(null);
  const [state] = useContext(AppContext);
  let height = 360;

  const options = useJaqlQueryAPI(
    state.jaqlQueryUtil,
    state.server!,
    [
      {
        ...jaql,
        filter: { multiSelection: true, explicit: false, all: true },
        level: jaql.level!,
      },
    ],
    jaql.datasource,
    true
  );
  const {
    level,
    levelTimeFrames,
    timeFrame,
    changeLevel,
    changeTimeFrame,
    getFilterJaql,
    resetFilter
  } = useTimeFrames(jaql);
  
  function closeFilter(){
    if(onClose)
    onClose(label)
    setOpen(false)
  }

  function openFilter(){
    if(onOpen)
      onOpen(label)
      setOpen(true)
  }

  const [inputText, setInputText] = useState(timeFrame.text);

  function handleInputClick() {
    openFilter()
  }

  function handleApplyFilter(){
    closeFilter()
    if(onSelectionChange)
      onSelectionChange(getFilterJaql())
  }

  function handleResetFilter(){

  } 

  function handleClickAway(){
    if(open){
      resetFilter()
      closeFilter()
    }
  
  }

  function handleLevelClick(level:DateTimeLevel){
      changeLevel(level)

  }

  function handleTimeFrameClick(frame:DateTimeFrame){
    changeTimeFrame(frame)
  }
  
  useEffect(()=>{
    setInputText(timeFrame.text)
  },[timeFrame])


  return (
    <ClickAwayListener onClickAway={handleClickAway}>

    <div
      ref={anchorRef}
      style={{ position: "relative", width: "280px", margin: "10px 12px" }}
    >
      <Grid container>
        <Typography
          variant="body1"
          margin="4px 6px"
          noWrap={true}
          width={246}
          color={open ? "primary" : ""}
        >
          {label}
        </Typography>
        <OutlinedInput
          fullWidth
          value={open ? filterText : inputText}
          onClick={handleInputClick}
          placeholder={inputText}
        />
      </Grid>
      <Popper open={open} anchorEl={anchorRef.current} style ={{zIndex:"3"}}>
        <Paper
          style={{
            height: "360px",
            width: "520px",
            position: "relative",
            left: "120px",
            top: "8px",
            visibility: open ? "visible" : "hidden",
          }}
        >
          <Grid container flexDirection="row" sx={{ height: "360px" }}>
            <Grid
              item
              container
              sx={{
                width: "200px",
                height: "100%",
                backgroundColor: "#f2f2f2",
              }}
            >
              <Grid item sm={12} padding="16px">
                <List>
                  {Object.values(DateTimeLevel).map((lvl) => (
                    <ListItem dense disablePadding disableGutters>
                      <ListItemButton
                        disableGutters
                        onClick={() => handleLevelClick(lvl)}
                      >
                        <Radio size="small" checked={level === lvl} />
                        <Typography variant="caption">{`${lvl
                          .charAt(0)
                          .toUpperCase()}${lvl.slice(
                          -(lvl.length - 1)
                        )}`}</Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              direction="column"
              sx={{ width: "320px", height: "360px" }}
            >
              <Grid item>
                <List sx={{ height: "270px", overflowY: "scroll" }}>
                  {levelTimeFrames.map((frame) => (
                    <ListItem dense>
                      <ListItemButton
                        disableGutters
                        onClick={() => handleTimeFrameClick(frame)}
                      >
                        <Radio
                          size="small"
                          sx={{ marginLeft: "8px" }}
                          checked={frame.text === timeFrame.text}
                        />
                        <Typography variant="caption">{frame.text}</Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid
                item
                container
                justifyContent="space-between"
                padding="16px"
                spacing={1}
              >
                <Grid item sm={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={handleApplyFilter}>
                    Apply filters
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button fullWidth variant="contained" color="secondary" onClick={handleResetFilter}>
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </div>
    </ClickAwayListener>

  );
}
