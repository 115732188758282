import { useCallback, useEffect, useState } from "react";
import {
  DateTimeLevel,
  jaql,
  jaqlTimeFrameFilter,
} from "../../components/EmbedSDK";
import { timeFrames, DateTimeFrame } from "../DateTimeFilter";
import _ from "lodash";

export default function useTimeFrames(jaql: jaql) {
  const [filterJaql, setFilterJaql] = useState(jaql);
  const [level, setLevel] = useState<DateTimeLevel>(jaql.level!);
  const [levelTimeFrames, setLevelTimeFrames] = useState<Array<DateTimeFrame>>(
    timeFrames[level]
  );

  const [timeFrame, changeTimeFrame] = useState<DateTimeFrame>(
    levelTimeFrames[0]
  );

  useEffect(() => {
    if (!_.isEqual(filterJaql, jaql)) {
      setFilterJaql(jaql);
      setFilterState(jaql);
    }
  }, [jaql]);

  function setFilterState(state: jaql) {
    let levelTfs = timeFrames[state.level!];
    let timeframe = levelTfs.find((tf) =>
      _.isEqual(tf.filter, state.filter as jaqlTimeFrameFilter)
    );

    setLevel(state.level!);
    changeTimeFrame(timeframe!);
    setLevelTimeFrames(levelTfs);
  }

  const getFilterJaql = useCallback(() => {
    let filter = timeFrame.filter;
    return { ...filterJaql, filter, level };
  }, [filterJaql, level, timeFrame]);

  const resetFilter = useCallback(() => {
    setFilterState(filterJaql);
  }, [filterJaql]);

  const changeLevel = useCallback((level: DateTimeLevel) => {
    setLevel(level);
    let levelTfs = timeFrames[level]
    setLevelTimeFrames(levelTfs);
    changeTimeFrame(levelTfs[0])
  }, []);

  return {
    level,
    timeFrame,
    levelTimeFrames,
    changeLevel,
    getFilterJaql,
    changeTimeFrame,
    resetFilter,
  };
}
