import { Skeleton } from "@mui/material";
import { SkeletonWrapperProps } from ".";

export default function SkeletonWrapper({
  backgroundColor,
  loading,
  children,
  ...other
}: SkeletonWrapperProps) {
  return loading ? (
    <Skeleton sx={{ backgroundColor }} {...other}>
      {children}
    </Skeleton>
  ) : (
    <>{children}</>
  );
}
