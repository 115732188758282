import { FilterOption, jaql } from "../../components/EmbedSDK";
import useJaqlQueryAPI from "./useJaqlQueryAPI";

type ApiStatus = "idle" | "loading" | "error" | "resolved";

type ApiResult = {
  getValues: () => Promise<Array<FilterOption>>;
  query:Array<jaql>
};

export type { ApiResult, ApiStatus };

export default useJaqlQueryAPI;
