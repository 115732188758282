import { useCallback, useEffect, useRef, useState } from "react";
import { FilterOption, jaql } from "../../components/EmbedSDK";
import { ApiResult, ApiStatus } from "./index";
import _ from "lodash"

export default function useJaqlQueryAPI(
  queryUtility: any,
  server: string,
  metadata: Array<jaql>,
  datasource: any,
  dynamic: boolean
): ApiResult {
  // const [status, setStatus] = useState<ApiStatus>("idle");
  const [values, setValues] = useState<Array<FilterOption> | null>(null);
  const [query, setQuery] = useState<Array<jaql>>(metadata);

  const runQuery = useCallback((): Promise<Array<FilterOption>> => {
    if (queryUtility) {
      // setStatus("loading");
      console.log("JAQL API Call");

      // jaql to query
      var payload = {
        datasource,
        isMaskedResponse: true,
        metadata: query,
      };
      // Make the actual query using the  SisenseJS built-in utility
      return queryUtility
        .post(`${server}/api/datasources/${datasource.title}/jaql`, payload)
        .then((response: any) => {
          let values: Array<FilterOption | string> = response.data.values.map(
            (value: any) => value[0]
          );

          // A little catch, cause in some cases the API does not respond with 'FilterOption' values
          if (values.length > 0 && typeof values[0] === "string") {
            values = values.map((value) => ({
              data: value as string,
              text: value as string,
            }));
          }

          console.log("API RESPONSE:", values);
          return values;
        })
        .catch((error: any) => {
          console.log("Error:", error);
          // setStatus("error");
          return error
        });
    } else {
      return Promise.reject("");
    }
  }, [query, queryUtility, server, datasource]);

  useEffect(() => {
    if (dynamic) {
      let result = _.isEqual(query, metadata);
      console.log("Changed Metadata", result, metadata);
      if (!result) {
        setValues(null);
        setQuery(metadata);
      }
    }
  }, [metadata]);

  const getValues = useCallback(() => {
    console.log("get values called", metadata);
    return new Promise<Array<FilterOption>>((resolve, reject) => {
      if (values) resolve(values);

      return runQuery().then((result =>{
        setValues(result)
        resolve(result)
      }))
    });
    
  }, [values, runQuery]);

  return { getValues, query };
}
