
import {jaql} from "../EmbedSDK"
import CascadingFilter from "./CascadingFilter";

type CascadingFilterProps = {
  levels:Array<jaql>
  onSelectionChange?: (selection: Array<jaql>)=> void;
  onOpen:(filterName:string) => void
  onClose:(filterName:string) => void
};

export type {
    CascadingFilterProps
}
export default CascadingFilter
