import { DashboardSection, User } from "../../context";
import Appbar from "./Appbar";
type AppbarProps = {
  onToggle: Function;
  user: User;
  currentSection: DashboardSection;
  open: boolean
};

export type { AppbarProps };

export default Appbar;
