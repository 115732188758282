import { ListItemButton, ListItemButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export default function SidebarTab(props: ListItemButtonProps) {
  const StyledTab = styled(ListItemButton)<ListItemButtonProps>(
    ({ theme }) => ({
      "&.MuiListItemButton-root": {
        backgroundColor: "transparent",
        color: "#ffffff76",
        padding: "16px",

        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.08)",
          color: "white",
        },
      },

      "&.Mui-selected": {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
        color: "white",
      },
    })
  );
  return <StyledTab {...props} />;
}
