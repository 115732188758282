import { styled } from "@mui/system";
import { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { MainProps } from "./index";
import config from "../../config";
import useScript from "../../hooks/useScript";


const StyledContent = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
  drawerWidth: number;
}>(({ theme, open, drawerWidth }) => ({
  flexGrow: 1,
  padding: "none",
  marginLeft: `-${drawerWidth}px`,
  transition: "all 100ms ease-out",
  ...(open && {
    marginLeft: 0,
    
  }),
}));

export default function Main({ drawerWidth, sidebarOpen, children }: MainProps) {
  

  return (
    <StyledContent open={sidebarOpen} drawerWidth={drawerWidth}>
        {children}
    </StyledContent>
  );
}
