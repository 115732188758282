import {
  AppBar,
  Grid,
  Typography,
  IconButton,
  Avatar,
  Tooltip,
  Box,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { ReactComponent as CollapseRight } from "../../resources/icons/collapse-right.svg";
import { ReactComponent as CollapseLeft } from "../../resources/icons/collapse-left.svg";
import { AppbarProps } from ".";
import SkeletonWrapper from "../SkeletonWrapper";

export default function Appbar({
  onToggle,
  user,
  currentSection,
  open,
}: AppbarProps) {
  return (
    <AppBar
      elevation={0}
      position="sticky"
      color="inherit"
      // sx={{ borderBottom: "1px solid #bababa" }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        padding="18px 40px 24px 40px"
        height="84px"
      >
        <Grid
          item
          container
          sm={8}
          justifyContent="flex-start"
          spacing={2}
          alignItems="center"
        >
          <Grid  item>
            <IconButton
              disableTouchRipple
              size="medium"
              sx={{
                width: 36,
                height: 36,
                padding: "8px",
                borderRadius: "4px",
              }}
              onClick={() => onToggle()}
            >
              {open ? <CollapseLeft /> : <CollapseRight />}
            </IconButton>
          </Grid>

          <Grid   item>
            <Typography
              variant="h2"
              noWrap
              component="div"
              // sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              {currentSection.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          sm={4}
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Box sx={{ flexGrow: 0 }}>
              <Avatar
                sx={{ bgcolor: "primary" }}
                alt={user && `${user.firstName[0]} ${user.lastName[0]}`}
                src="/static/images/avatar/2.jpg"
              >
                {user && `${user.firstName[0]}${user.lastName[0]}`}
              </Avatar>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {user && `${user.firstName} ${user.lastName}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}
