import { useState, useEffect, useContext } from "react";
import { jaql } from "../EmbedSDK";
import { CascadingFilterProps } from "./index";
import { AppContext } from "../../context";
import AutocompleteFilter from "../AutoCompleteFilter";
import { Grid } from "@mui/material";
import _ from "lodash"

export default function CascadingFilter({
  levels,
  onSelectionChange,
  onOpen,
  onClose
}: CascadingFilterProps) {
  const [state] = useContext(AppContext);
  const [filterJaql, setFilterJaql] = useState<Array<jaql>>(levels);
  // const [filterLevels, setFilterLevels] = useState<Array<jaql>>(levels);

  useEffect(()=>{
    if (!_.isEqual(levels, filterJaql)) {
      console.log("$$$ papaya");
      setFilterJaql([...levels]);
    }
  },[levels])

  function handleLevelChange(level: string, selection: jaql) {
    let levelIndex = filterJaql.findIndex((l) => l.title === level);
    filterJaql.splice(levelIndex, 1, selection);
    setFilterJaql([...filterJaql]);
    if (onSelectionChange) onSelectionChange(filterJaql);

    console.log("Level changed, jaql of cascading is =>", filterJaql)
  }

  function handleLevelClose(levelName:string){
    if(onClose)
      onClose(levelName)
  }

  function handleLevelOpen(levelName:string){
    if(onOpen)
      onOpen(levelName)
  }

  function getLevelQuery(index: number) {
    return (
      filterJaql
        // Levels below ours should not care
        .filter((q, i) => i <= index)
        .map(
          (q, i): jaql => ({
            ...q,
            panel: i === index ? "" : "scope", // Include only data of this level index in results
            filter:
              i === index
                ? { explicit: false, multiSelection: true, all: true }
                : q.filter, // Include all posible results for this level
          })
        )
    );
  }

  return (
    <Grid container item flexWrap={"wrap"} sm="auto" margin={"0px 0px"}>
      {filterJaql.map((level, index) => (
        <Grid item key={level.title}>
          <AutocompleteFilter
            onOpen={handleLevelOpen}
            onClose={handleLevelClose}
            jaql={filterJaql[index]}
            levels={getLevelQuery(index)}
            label={level.title}
            isDisabled={false}
            onSelectionChange={(value) => handleLevelChange(level.title, value)}
          />
        </Grid>
      ))}
    </Grid>
  );
}
