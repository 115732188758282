import {
  CascadingDashboardFilter,
  DashboardFilter,
  DateTimeDashboardFilter,
  ExportMode,
  TextDashboardFilter,
} from "../components/EmbedSDK";
import React, { useEffect, useReducer } from "react";
import {
  UpdateFilterAction,
  AppContextAction,
  AppContextType,
  DashboardPage,
  DashboardSection,
  SetUserAction,
  User,
  UpdateDashboardPageAction,
  SetActivePageAction,
  SetActiveSectionAction,
  UpdateSectionFilterAction,
  UpdatePageFilterAction,
  SetSectionFiltersAction,
  SetFiltersAction,
  SetPageFiltersAction,
  ExportDashboardAction,
  SetSectionDefaultFiltersAction,
  SetFilterOpenAction,
} from "./index";
import { sections } from "../content.json";
import _ from "lodash";

export const defaultContext: AppContextType = {
  user: null,
  sections: [],
  server: "",
  jaqlQueryUtil: null,
  exportDashboard: { exportMode: null },
};

function reducer(
  state: AppContextType,
  action: AppContextAction
): AppContextType {
  if (action.type === "SET_APP_CONTEXT") return action.payload;

  return {
    ...state,
    sections: sectionsReducer(state.sections, action),
    user: userReducer(state.user!, action),
    jaqlQueryUtil: queryUtilReducer(state.jaqlQueryUtil, action),
    exportDashboard: exportDashboardReducer(state.exportDashboard, action),
  };
}

function exportDashboardReducer(
  exportDashboard: {
    exportMode: ExportMode | null;
  },
  action: AppContextAction
) {
  switch (action.type) {
    case "EXPORT_DASHBOARD":
      return { exportMode: (action as ExportDashboardAction).payload };
    default:
      return exportDashboard;
  }
}

function userReducer(user: User, action: AppContextAction): User {
  switch (action.type) {
    case "SET_USER":
      return { ...(action as SetUserAction).payload };

    default:
      return user;
  }
}

function queryUtilReducer(queryUtil: any, action: AppContextAction): any {
  switch (action.type) {
    case "SET_QUERY_UTIL":
      return { ...action.payload };
    default:
      return queryUtil;
  }
}

function sectionsReducer(
  sections: Array<DashboardSection>,
  action: AppContextAction
): Array<DashboardSection> {
  switch (action.type) {
    case "UPDATE_DASHBOARD_PAGE":
      return (() => {
        let { page, sectionTitle } = (action as UpdateDashboardPageAction)
          .payload;

        let sectionIndex = sections.findIndex((s) => s.title === sectionTitle);
        let section = sections[sectionIndex];
        let pageIndex = section!.pages.findIndex((p) => p.title === page.title);

        section!.pages.splice(pageIndex, 1, page);
        sections.splice(sectionIndex, 1, section!);
        return [...sections];
      })();

    case "SET_ACTIVE_PAGE":
      return (() => {
        let { page, sectionTitle } = (action as SetActivePageAction).payload;

        let sectionIndex = sections.findIndex((s) => s.title === sectionTitle);
        let section = sections[sectionIndex];

        section.pages = section.pages.map((p) => ({
          ...p,
          active: p.title === page.title ? true : false,
        }));
        section!.active = true;
        sections.splice(sectionIndex, 1, section);

        return [...sections];
      })();

    case "SET_ACTIVE_SECTION":
      return (() => {
        let { sectionTitle } = (action as SetActiveSectionAction).payload;
        let newSections = sections.map((s) => ({
          ...s,
          active: s.title === sectionTitle ? true : false,
          pages: s.pages.map((p) => ({ ...p, loading: true })),
        }));
        return [...newSections];
      })();

    case "UPDATE_SECTION_FILTER":
      return (() => {
        let { sectionTitle } = (action as UpdateSectionFilterAction).payload;
        let section = sections.find((s) => s.title === sectionTitle);
        section!.filters = filtersReducer(section!.filters!, {
          ...action,
          type: "UPDATE_FILTER",
        });
        return [...sections];
      })();

    case "UPDATE_PAGE_FILTER":
      return (() => {
        let { sectionTitle, pageTitle } = (action as UpdatePageFilterAction)
          .payload;
        let page = sections
          .find((s) => s.title === sectionTitle)
          ?.pages.find((p) => p.title === pageTitle);
        page!.filters = filtersReducer(page!.filters!, {
          ...action,
          type: "UPDATE_FILTER",
        });
        return [...sections];
      })();

    case "SET_SECTION_FILTERS":
      return (() => {
        let { sectionTitle, filters } = (action as SetSectionFiltersAction)
          .payload;
        let section = sections.find((s) => s.title === sectionTitle);
        section!.filters = filtersReducer(section!.filters!, {
          payload: filters,
          type: "SET_FILTERS",
        });
        return [...sections];
      })();

    case "SET_SECTION_DEFAULT_FILTERS":
      return (() => {
        let { sectionTitle, filters } = (
          action as SetSectionDefaultFiltersAction
        ).payload;
        let section = sections.find((s) => s.title === sectionTitle);
        section!.defaultFilters = filtersReducer(section!.filters!, {
          payload: filters,
          type: "SET_FILTERS",
        });
        return [...sections];
      })();

    case "SET_PAGE_FILTERS":
      return (() => {
        let { sectionTitle, pageTitle, filters } = (
          action as SetPageFiltersAction
        ).payload;
        let page = sections
          .find((s) => s.title === sectionTitle)
          ?.pages.find((p) => p.title === pageTitle);
        page!.filters = filtersReducer(page!.filters!, {
          payload: filters,
          type: "SET_FILTERS",
        });
        return [...sections];
      })();

    case "SET_FILTER_OPEN":
      return (() => {
        let { sectionTitle, filterName, open } = (action as SetFilterOpenAction)
          .payload;

        let section = sections.find((s) => s.title === sectionTitle);
        let openFilters = [...section!.openFlters];

        if (open) openFilters.push(filterName);
        else openFilters = openFilters.filter((fn) => fn !== filterName);

        section!.openFlters = openFilters;

        return [...sections];
      })();

    default:
      return sections;
  }
}

function filtersReducer(
  filters: Array<DashboardFilter>,
  action: AppContextAction
): Array<DashboardFilter> {
  switch (action.type) {
    case "UPDATE_FILTER":
      let { payload } = action as UpdateFilterAction;
      const filter = filters.find((f) => f.name === payload.filter.name); // name
      const index = filters.indexOf(filter!);
      let newFilter: DashboardFilter;

      if (filter) {
        switch (filter.type) {
          case "text":
            newFilter = payload.filter as TextDashboardFilter;
            console.log("New Filter", newFilter);
            filters.splice(index, 1, newFilter);

            return [...filters];

          case "datetime":
            newFilter = payload.filter as DateTimeDashboardFilter;
            console.log("New Filter", newFilter);
            filters.splice(index, 1, newFilter);

            return [...filters];
          case "cascading":
            newFilter = payload.filter as CascadingDashboardFilter;
            console.log("New Filter", newFilter);
            filters.splice(index, 1, newFilter);

            return [...filters];

          default:
            throw new Error(`filter type '${filter.type}' not supported`);
        }
      }
      throw new Error(`filter '${payload.filter.name}' not found`);

    case "SET_FILTERS":
      return (action as SetFiltersAction).payload;
    default:
      return filters;
  }
}

export const AppContext = React.createContext<
  [AppContextType, React.Dispatch<AppContextAction>]
>([defaultContext, () => {}]);

export function AppContextProvider({
  children,
  context,
}: {
  children: any;
  context: AppContextType;
}) {
  const [state, dispatch] = useReducer(reducer, context);

  useEffect(() => {
    dispatch({ type: "SET_APP_CONTEXT", payload: context });
  }, [context]);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
}
