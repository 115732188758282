import "./App.css";
import CompanyDashboard from "./components/CompanyDashboard";
import { AppContextProvider, defaultContext } from "./context/AppContext";
import { ThemeProvider } from "@mui/material/styles";
import lightTheme from "./resources/themes/light";
import config from "./config";
import useScript from "./hooks/useScript";
import { useEffect, useState } from "react";
import createInitialContext from "../src/utils/createInitialContext";
import ErrorPage from "./components/ErrorPage";

declare var Sisense: any;

type AppStatus = "Idle" | "Error" | "Success";
export type ErrorType = "None" | "Unauthorized" | "Connection";

function App() {
  const [appContext, setAppContext] = useState(defaultContext);
  const [appStatus, setAppStatus] = useState<AppStatus>("Idle");
  const [errorType, setErrorType] = useState<ErrorType>("None");

  const sisenseLoaded = useScript(
    "https://wizeline.sisense.com/js/sisense.js",
     false
  );

  useEffect(() => {
    if (sisenseLoaded) {
      Sisense.connect(config.server)
        .then(async (app: any) => {
          console.log("SisenseJs", app);
          let initialContext = await createInitialContext(app, appContext);

          console.log("Initial Context", initialContext)
          // Request for user dashboard succeed
          if (initialContext) {
            // User has acces to some dashboards
            if (initialContext.sections.length > 0) {
              setAppContext(initialContext);
              setAppStatus("Success");
            }
            // User has no acces to any of the dashboards
            else {
              setAppStatus("Error");
              setErrorType("Unauthorized");
            }
          }
          // Request for user dashboards failed
          else {
            setAppStatus("Error");
            setErrorType("Connection");
          }
        })
        // Error ocurred trying to connect to Sisense
        .catch((error: any) => {
          setAppStatus("Error");
          setErrorType("Connection");
        });
    }
  }, [sisenseLoaded]);

  return (
    <AppContextProvider context={appContext}>
      <ThemeProvider theme={lightTheme}>
        {appStatus !== "Error" && (
          <CompanyDashboard loading={appStatus === "Idle"} />
        )}
        {appStatus === "Error" && <ErrorPage errorType={errorType} />}
      </ThemeProvider>
    </AppContextProvider>
  );
}

export default App;
