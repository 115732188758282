import { ReactChild } from "react";
import { DashboardPage } from "../../context";
import Content from "./Content";

type MainProps = {
  drawerWidth: number;
  children: ReactChild
  sidebarOpen:boolean
};

export type { MainProps };

export default Content
