import { TimelineSeparator } from "@material-ui/lab";
import {
  Grid,
  Typography,
  Checkbox,
  Paper,
  Button,
  Popper,
  OutlinedInput,
  ListItem,
  ListItemButton,
  ClickAwayListener,
  Divider,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { AutocompleteFilterProps } from ".";
import { AppContext } from "../../context";
import useJaqlQueryAPI from "../../hooks/JaqlQueryAPI";
import useSimpleFilter from "../../hooks/SimpleFilter";
import { FilterOption, SelectionFormat } from "../EmbedSDK";
import "./AutocompleteFilter.css";

function RenderOption(props: ListChildComponentProps) {
  const { index, style, data } = props;

  return React.cloneElement(data[index], {
    style,
  });
}

type FilterMenuProps = {
  children?: any;
  onClearSelection?: Function;
  onSaveSelection?: Function;
  selectionFormat?: SelectionFormat;
  props?: any;
};

function Papercut({
  children,
  onClearSelection,
  onSaveSelection,
  selectionFormat,
}: FilterMenuProps) {
  let items = React.Children.toArray(children);

  function handleReset() {
    if (onClearSelection) onClearSelection();
  }

  function handleSave() {
    if (onSaveSelection) onSaveSelection();
  }

  return (
    <Paper>
      <Grid container flexDirection="column" padding="16px">
        <Grid item>
          <ListItem dense disablePadding disableGutters onClick={handleReset} >
            <ListItemButton disableGutters>
              <Checkbox
                checked={selectionFormat === "Include All"}
                indeterminate={
                  selectionFormat === "All Exept" ||
                  selectionFormat === "Include Only"
                }
                sx={{ marginLeft: "8px" }}
                size="small"
              />
              <Typography variant="caption">Select all</Typography>
            </ListItemButton>
          </ListItem>
          <Divider
            sx={{ color: "e6e6e6", marginTop: "4px", marginBottom: "4px" }}
          />
        </Grid>
        <Grid item container>
          <FixedSizeList
            itemData={items}
            width="100%"
            height={250}
            itemSize={52}
            itemCount={items.length}
            overscanCount={15}
          >
            {RenderOption}
          </FixedSizeList>
        </Grid>
        <Grid item container spacing={2} padding="20px 0px 0px 0px">
          <Grid item sm={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function AutocompleteFilter({
  jaql,
  label,
  isDisabled,
  onSelectionChange,
  onDisableChange,
  levels,
  onOpen,
  onClose
}: AutocompleteFilterProps) {
  const [state] = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const anchorRef = React.useRef<HTMLDivElement | null>(null);

  const apiResult = useJaqlQueryAPI(
    state.jaqlQueryUtil,
    state.server!,
    levels ? levels : [jaql],
    jaql.datasource,
    levels !== undefined
  );

  const {
    getItems,
    selectionFormat,
    getFilterJaql,
    isSelected,
    toggleSelection,
    toggleAll,
    cleanFilter,
    filterItems,
    getSelectedItems,
  } = useSimpleFilter(jaql, apiResult);

  const [inputText, setInputText] = useState(renderTags(getSelectedItems()));

  function handleFilterSave() {
    setInputText(renderTags(getSelectedItems()));
    setFilterText("");
    closeFilter()
    if (onSelectionChange) onSelectionChange(getFilterJaql());
  }

  function handleFilterClear() {
    toggleAll();
  }

  function handleInputClick() {
    setInputText("Search...");
    openFilter()
  }

  function handleClickAway() {
    if (open) {
      cleanFilter();
      setFilterText("");
      closeFilter()
    }
  }

  function closeFilter(){
    setOpen(false)
    if(onClose)
      onClose(label)
  }

  function openFilter(){
    setOpen(true)
    if(onOpen)
      onOpen(label)
  }

  function renderTags(value: Array<FilterOption>) {
    let strValue = "";
    if (selectionFormat === "None" || selectionFormat === "Include All")
      strValue = selectionFormat;
    else {
      for (let i = 0; i < value.length; i++) {
        strValue +=
          i === value.length - 1 ? `${value[i].text}` : `${value[i].text}, `;

        if (strValue.length >= 28) {
          return `${strValue.slice(0, 28)}...`;
        }
      }
    }

    return strValue;
  }

  useEffect(() => {
    filterItems(filterText);
  }, [filterText]);

  useEffect(() => {
    setInputText(renderTags(getSelectedItems()));
  }, [getSelectedItems]);

  return (
    <ClickAwayListener onClickAway={handleClickAway} >
      <div
        ref={anchorRef}
        style={{ position: "relative", width: "280px", margin: "10px 12px" }}
      >
        <Grid container>
          <Typography
            variant="body1"
            margin="4px 6px"
            noWrap={true}
            width={246}
            color={open ? "primary" : ""}
          >
            {label}
          </Typography>
          <OutlinedInput
            fullWidth
            value={open ? filterText : inputText}
            onChange={(event) => setFilterText(event.target.value)}
            onClick={handleInputClick}
            placeholder={inputText}
          />
        </Grid>
        <Popper open={open} anchorEl={anchorRef.current} disablePortal={false} style={{zIndex:"3"}}  >
          <div style={{ display: "flex", flexDirection: "column", }}>
            <ul
              style={{
                height: "300px",
                width: anchorRef.current
                  ? anchorRef.current.clientWidth
                  : "none",
                position: "relative",
                left: "0px",
                top: "8px",
                visibility: open ? "visible" : "hidden",
              }}
            >
              <Papercut
                onSaveSelection={handleFilterSave}
                onClearSelection={handleFilterClear}
                selectionFormat={selectionFormat}
              >
                {getItems().length > 0 ? (
                  getItems().map((option, index) => (
                    <ListItem
                      dense
                      disablePadding
                      disableGutters
                      onClick={() => toggleSelection(option as FilterOption)}
                    >
                      <ListItemButton disableGutters>
                        <Checkbox
                          key={(option as FilterOption).text}
                          checked={isSelected(option)}
                          sx={{ marginLeft: "8px" }}
                          size="small"
                        />
                        <Typography variant="caption">
                          {(option as FilterOption).text.substring(
                            0,
                            (option as FilterOption).text.length < 28
                              ? (option as FilterOption).text.length
                              : 28
                          )}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ))
                ) : (
                  <Typography>No results</Typography>
                )}
              </Papercut>
            </ul>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
