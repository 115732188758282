import { Card, Grid, Typography, Button, SvgIcon } from "@mui/material";
import { useContext } from "react";
import { FiltersSectionProps } from ".";
import { AppContext } from "../../context";
import { ReactComponent as FilterIcon } from "../../resources/icons/filter.svg";
import { ReactComponent as DeleteIcon } from "../../resources/icons/trash-can.svg";
import AutocompleteFilter from "../AutoCompleteFilter";
import CascadingFilter from "../CascadingFilter";
import { DateTimeFilterX } from "../DateTimeFilter";
import {
  CascadingDashboardFilter,
  TextDashboardFilter,
  DateTimeDashboardFilter,
  DashboardFilter,
} from "../EmbedSDK";
import _ from "lodash";

export default function FiltersSection({ section }: FiltersSectionProps) {
  const [state, dispatch] = useContext(AppContext);

  function handleFilterUpdate(filter: DashboardFilter) {
    dispatch({
      type: "UPDATE_SECTION_FILTER",
      payload: { sectionTitle: section.title, filter },
    });
  }

  function handleSectionFiltersClear() {
    dispatch({
      type: "SET_SECTION_FILTERS",
      payload: {
        sectionTitle: section.title,
        filters: _.cloneDeep(section.defaultFilters!),
      },
    });
  }

  function handleFilterOpen(filterName: string) {
    dispatch({
      type: "SET_FILTER_OPEN",
      payload: {
        filterName,
        open: true,
        sectionTitle: section.title,
      },
    });
  }

  function handleFilterClose(filterName: string) {
    dispatch({
      type: "SET_FILTER_OPEN",
      payload: {
        filterName,
        open: false,
        sectionTitle: section.title,
      },
    });
  }

  return (
    <Card
      component="section"
      variant="outlined"
      sx={{
        backgroundColor: "#F2F2F2",
        position: "sticky",
        zIndex: "1",
        width: "100%",
        borderRadius: "0px",
        top: "20px",
      }}
    >
      <Grid container padding="22px 18px 18px 18px">
        <Grid item container justifyContent="space-between">
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            margin="0px 0px 8px 0px"
            sm={4}
          >
            <Grid item>
              <FilterIcon />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">Filter all dashboards</Typography>
            </Grid>
          </Grid>
          <Grid item container sm={4} justifyContent="end">
            {section.defaultFilters &&
              !_.isEqual(section.filters, section.defaultFilters) && (
                <Button color="primary" onClick={handleSectionFiltersClear}>
                  <Grid container alignItems="center">
                    <SvgIcon component={DeleteIcon} fontSize="small" />
                    <Grid item>Clear all filters</Grid>
                  </Grid>
                </Button>
              )}
          </Grid>
        </Grid>
        <Grid item sm={12} flexWrap={"wrap"} flexDirection={"row"} container>
          {section.filters &&
            section.filters.map((filter) => {
              if (filter.type === "cascading")
                return (
                  <CascadingFilter
                    onOpen={handleFilterOpen}
                    onClose={handleFilterClose}
                    levels={(filter as CascadingDashboardFilter).levels}
                    onSelectionChange={(selection) =>
                      handleFilterUpdate({
                        ...filter,
                        levels: selection,
                      } as CascadingDashboardFilter)
                    }
                  />
                );

              if (filter.type === "text")
                return (
                  <AutocompleteFilter
                    onOpen={handleFilterOpen}
                    onClose={handleFilterClose}
                    jaql={(filter as TextDashboardFilter).jaql!}
                    label={filter.name}
                    onSelectionChange={(selection) =>
                      handleFilterUpdate({
                        ...filter,
                        jaql: selection,
                      } as TextDashboardFilter)
                    }
                  />
                );

              if (filter.type === "datetime")
                return (
                  <DateTimeFilterX
                    onOpen={handleFilterOpen}
                    onClose={handleFilterClose}
                    jaql={(filter as DateTimeDashboardFilter).jaql}
                    label={filter.name}
                    onSelectionChange={(selection) =>
                      handleFilterUpdate({
                        ...filter,
                        jaql: selection,
                      } as DateTimeDashboardFilter)
                    }
                  />
                );
            })}
        </Grid>
      </Grid>
    </Card>
  );
}
