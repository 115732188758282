import { useEffect, useState } from "react";
import { v4 } from "uuid";

export default function useScript(src: string, nonCache: boolean) {
  const [loaded, setloaded] = useState(false);

  useEffect(() => {
    var embedsdk = document.createElement("script");
    embedsdk.type = "text/javascript";
    embedsdk.src = nonCache ? `${src}?${v4()}` : src;
    embedsdk.async = false;
    embedsdk.onload = () => {
      setloaded(true);
    };
    document.head.append(embedsdk);
  }, []);

  return loaded;
}
