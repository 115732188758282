import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { EmbedSdkProps, SisenseFrame, Dashboard } from "./index";

const EmbedSDK = ({
  dashboardOid,
  serverUrl,
  filters,
  settings = { showLeftPane: false, showToolbar: false, showRightPane: false },
  visible,
  shouldRender,
  onDashboardLoad,
  exportDashboard,
  loaderComponent,
  addOverlay
}: EmbedSdkProps) => {
  const frameRef = React.useRef<HTMLIFrameElement | null>(null);
  const sisenseFrame = React.useRef<SisenseFrame | null>(null);
  const dashboardRef = React.useRef<Dashboard | null>(null);
  const [loading, setLoading] = useState(true);
  const [frameHeight, setFrameHeight] = useState(0);
  const [componentHeight, setComponentHeigh] = useState(0);

  useEffect(() => {
    if (frameRef.current) {
      let elementY = frameRef.current.getBoundingClientRect().y;
      let height = (window.innerHeight - elementY!) * 0.8;
      setComponentHeigh(height);
    }
  }, []);

  console.log(`EmbedSDK - Render Count: ${dashboardOid}`);

  function renderFrame() {
    console.log(`EmbedSDK - Render frame called for ${dashboardOid}`);
    const embed = (window as { [key: string]: any })["sisense.embed"];
    const SisenseFrame = embed.SisenseFrame;

    const frame: SisenseFrame = new SisenseFrame({
      // Sisense application URL, including protocol and port if required
      url: serverUrl,
      // OID of dashboard to load initially
      dashboard: dashboardOid,
      // Which panels to show in the iFrame
      settings: settings,
      // You can define existing <iframe/> DOM element here or
      // pass some <div/> as a parameter to render function below
      // and <iframe/> will be rendered inside that <div/> dynamically
      element: frameRef.current,
      // element: document.getElementById("sisense-frame"),˚
    });

    console.log("EmbedSDK - Instance", frame);
    sisenseFrame.current = frame as SisenseFrame;
    console.log("EmbedSDK - dashboard", dashboardRef.current);

    frame
      .render()
      .then(() => {
        console.log("EmbedSDK - frame rendered");
        dashboardRef.current = frame.dashboard;
        loadDashboard();
      })
      .catch((e) => console.log("EmbedSDK - NOT CONNECTED", e));
  }

  useEffect(() => {
    console.log("EmbedSDK - shouldRender changed", shouldRender);
    if (shouldRender && !sisenseFrame.current) {
      renderFrame();
    }
  }, [shouldRender]);

  const applyFilters = useCallback(() => {
    if (filters && dashboardRef.current) {
      console.log(
        `EmbedSDK - These are the filters for  ${dashboardOid}:`,
        filters
      );
      dashboardRef.current.applyFilters(filters, false).then(() => {});
    }
  }, [filters, dashboardOid]);

  useEffect(() => {
    applyFilters();
  }, [filters]);

  // load another dashboard
  const loadDashboard = useCallback(() => {
    if (dashboardRef.current) {
      setLoading(true);
      if (frameRef.current) {
        let elementY = frameRef.current.getBoundingClientRect().y;
        let height = (window.innerHeight - elementY!) * 0.8;
        setComponentHeigh(height);
      }

      dashboardRef.current.open(dashboardOid, false).then((dashboard) => {
        console.log(`EmbedSDK - Dashboard: ${dashboardOid} Loaded`);

        dashboardRef.current = sisenseFrame.current!.dashboard;

        // Resize the frame to the actual content size
        sisenseFrame
          .current!.getSize()
          .then((size) => setFrameHeight(size.content.height));

        applyFilters();

        if (onDashboardLoad) onDashboardLoad(dashboardOid);
        setLoading(false);
      });
    }
  }, [onDashboardLoad, applyFilters, dashboardOid]);

  useEffect(() => {
    console.log("EmbedSDK - dashboardoid changed", dashboardOid);
    loadDashboard();
  }, [dashboardOid]);

  // Trigger export
  useEffect(() => {
    if (dashboardRef.current && visible) {
      dashboardRef.current.export(exportDashboard.exportMode!);
    }
  }, [exportDashboard]);

  return (
    <Grid
      sm={12}
      container
      item
      visibility={visible ? "visible" : "collapse"}
      direction="column"
      height={
        loading && visible ? `${componentHeight}px` : !visible ? "0px" : "auto"
      }
      justifyContent="center"
    >
      <div
        className="overlay"
        style={{
          visibility: addOverlay? "visible": "collapse",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      ></div>
      <iframe
        title="sisense embed"
        id={`sisense-frame-${dashboardOid}`}
        ref={frameRef}
        style={{
          visibility: !loading && visible ? "visible" : "collapse",
          height: !loading && visible ? `${frameHeight}px` : "0px",
        }}
      />
      {loading && loaderComponent ? (
        <Grid item>{loaderComponent()}</Grid>
      ) : null}
    </Grid>
  );
};

export default EmbedSDK;
