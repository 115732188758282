import { DashboardPage, DashboardSection } from "../../context";
import { DashboardFilter } from "../EmbedSDK";
import DashboardsSection from "./DashboardsSection";

type DashboardsSectionProps = {
  filters:Array<DashboardFilter>
  pages: Array<DashboardPage>;
  onPageChange: (page: DashboardPage) => void;
  onPageLoad:(dashboardOid:string) => void,
  currentSection:DashboardSection
};

export type { DashboardsSectionProps };

export default DashboardsSection;
