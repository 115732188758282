import {
  Grid,
  Tabs,
  Typography,
  Box,
  Tab,
  Button,
  SvgIcon,
} from "@mui/material";
import { useCallback, useContext, useEffect } from "react";
import { DashboardsSectionProps } from ".";
import config from "../../config";
import { AppContext, DashboardPage } from "../../context";
import EmbedSDK, { DashboardFilter, ExportMode } from "../EmbedSDK";
import { ReactComponent as ExportIcon } from "../../resources/icons/export.svg";
import WizeLoader from "../WizeLoader";
import _ from "lodash";

function TabPanel(props: any) {
  const { children, value, name, ...other } = props;
  return (
    <div
      role="tabpanel"
      // hidden={value !== name}
      id={`vertical-tabpanel-${name}`}
      aria-labelledby={`vertical-tab-${name}`}
      {...other}
    >
      {value === name && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function DashboardsSection({
  pages,
  filters,
  onPageChange,
  onPageLoad,
  currentSection
}: DashboardsSectionProps) {
  const [state, dispatch] = useContext(AppContext);

  const handleDashboardLoad = useCallback(
    (oid: string) => {
      if (onPageLoad) {
        onPageLoad(oid);
      }
    },
    [onPageLoad]
  );

  function handleExportClick(exportMode: ExportMode) {
    dispatch({
      type: "EXPORT_DASHBOARD",
      payload: exportMode,
    });
  }

  function handleDashboardClick(){
    console.log("Hermoso Click")
  }

  useEffect(() => {
    console.log("Active section pages changes");
  }, [pages]);

  return (
    <>
      <Grid margin="24px 0px" item container sm={12}>
        <Grid item container flexDirection="row" sm={8} alignContent="center">
          <Tabs
            value={pages.find((p) => p.active)?.title}
            onChange={(event, value) =>
              onPageChange(pages.find((p) => p.title === value)!)
            }
          >
            {pages.map((page) => (
              <Tab key={page.title} label={page.title} value={page.title} />
            ))}
          </Tabs>
        </Grid>
        <Grid item container sm={4} justifyContent="flex-end">
            <Button
              style={{width:"142px"}}
              disabled={pages.find((p) => p.active)?.loading}
              variant="contained"
              onClick={() => handleExportClick(ExportMode.PDF)}
            >
              <Grid container alignItems="center" justifyContent="center">
                <SvgIcon component={ExportIcon} fontSize="small" />
                <Grid item>Export</Grid>
              </Grid>
            </Button>
        </Grid>
      </Grid>
      <Grid sm={12} item container style={{ position: "relative" }}>
        {pages.map((page) => (
          <Grid
            key={page.title}
            item
            container
            justifyContent="center"
            alignContent="center"
          >
            <EmbedSDK
              addOverlay= {currentSection.openFlters.length>0}
              loaderComponent={WizeLoader}
              exportDashboard={state.exportDashboard}
              dashboardOid={page.dashboardOid}
              serverUrl={config.server!}
              filters={filters}
              visible={page.active}
              shouldRender={
                page.defaultActive! ||
                pages.find((p) => p.defaultActive)?.loading === false
              }
              onDashboardLoad={handleDashboardLoad}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
