import AutocompleteFilter from "./AutoCompleteFilter";
import {jaql} from "../EmbedSDK"


type AutocompleteFilterProps = {
  jaql: jaql;
  label: string;
  isDisabled?: boolean;
  onSelectionChange: (value: jaql) => void;
  onDisableChange?: Function;
  levels?: Array<jaql>;
  onOpen:(filterName:string) => void;
  onClose:(filterName:string) => void;

};

export type { AutocompleteFilterProps };
export default AutocompleteFilter;
