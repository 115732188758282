import { ReactComponent as WizeLogoGray } from "../../resources/icons/wize-logo-gray.svg";
import { Grid, Typography } from "@mui/material";
import "./wizeLoader.css";

export default function WizeLoader() {
  return (
    <Grid container direction="column" alignContent="center" textAlign="center">
      <Grid item>
        <WizeLogoGray className="wize-logo-animated" />
      </Grid>
      <Grid item padding="10px">
        <Typography variant="subtitle1" sx={{ color: "#747474" }}>
          Loading data...
        </Typography>
      </Grid>
    </Grid>
  );
}
