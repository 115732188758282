import { Drawer, Grid, List, ListItemText } from "@mui/material";
import { SidebarProps } from ".";
import Loader from "../Loader";
import wizeLogo from "../../resources/images/wize-logo.png";
import SidebarTab from "../SidebarTab";
import SkeletonWrapper from "../SkeletonWrapper";

export default function SideBar({
  width,
  open,
  onTabClick,
  sections,
  loading,
}: SidebarProps) {
  return (
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width,
          boxSizing: "border-box",
        },
      }}
      SlideProps={{
        direction: "right",
        easing: {
          enter: "ease-out",
          exit: "ease-out",
        },
        timeout: 160,
      }}
      variant="persistent"
      open={open}
      hideBackdrop={true}
      PaperProps={{
        sx: { padding: "90px 24px 40px", backgroundColor: "#111D29" },
      }}
    >
      <Grid container justifyContent="center">
        <Grid item marginBottom="50px">
          <img src={wizeLogo} height={70} alt="wizeline logo" />
        </Grid>
        <Grid item sm={12}>
          {loading ? (
            [1, 2, 3, 4, 5].map((item) => (
              <Grid marginBottom="20px">
                <SkeletonWrapper
                  height="38px"
                  backgroundColor="#e3e3e339"
                  loading={loading}
                  animation="wave"
                  variant="text"
                  key={item}
                ></SkeletonWrapper>
              </Grid>
            ))
          ) : (
            <List>
              {sections.map((section) => (
                <SidebarTab
                  selected={section.active}
                  onClick={() => onTabClick(section.title)}
                  disabled={false}
                  sx={{ marginBottom: "8px" }}
                >
                  {section.title}
                  {false && <Loader size="sm" />}
                </SidebarTab>
              ))}
            </List>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
}
