import { DashboardSection } from "../../context";
import { DashboardFilter } from "../EmbedSDK";
import FiltersSection from "./FiltersSection";

type FiltersSectionProps ={
    section:DashboardSection
}

export type {
    FiltersSectionProps
}

export default FiltersSection