import _ from "lodash";
import { parseFilters } from "../utils/Filter";
import config from "../config";
import { AppContextType, DashboardPage, DashboardSection } from "../context";
import { DashboardFilter } from "../components/EmbedSDK";
import content from "../content.json";

type UserDashboard = {
  oid: string;
  title: string;
  defaultFilters: Array<DashboardFilter>;
};

export default function createInitialContext(
  app: any,
  defaultContext: AppContextType
): Promise<AppContextType> {
  let sisenseAPI = app.$$http;
  let appUser = app.$$dashboard.$identity.user;
  console.log("dash endpoint", config.dashboardsEndpoint);

  return app.$$http
    .get(
      `${config.server}/${config.dashboardsEndpoint}?fields=oid,title,defaultFilters`
    )
    .then((result: any) => {
      console.log("RS", result);
      let userDashboarsHash: { [key: string]: UserDashboard } =
        result.data.reduce(
          (
            acc: { [key: string]: UserDashboard },
            dashboard: UserDashboard,
            index: number
          ) => {
            acc[dashboard.oid] = dashboard;
            return acc;
          },
          {}
        );

      console.log("HASH", userDashboarsHash);

      let appSections: Array<DashboardSection> = [];

      content.sections.forEach((section) => {
        let sectionFilters: Array<DashboardFilter> = [];
        let pages: Array<DashboardPage> = [];

        section.pages.forEach((page) => {
          let allowedDashboard = userDashboarsHash[page.dashboardOid];
          // It is allowed the dashboard, create a page
          if (allowedDashboard) {
            let firstPage = false;
            if (pages.length === 0) {
              firstPage = true;
            }
            pages.push({
              defaultActive: firstPage,
              title: page.title,
              dashboardOid: page.dashboardOid,
              loading: true,
              active: firstPage,
            });

            if (firstPage)
              sectionFilters = parseFilters(allowedDashboard.defaultFilters);
          }
        });

        // If there are pages, create the section
        if (pages.length > 0) {
          let firstSection = false;
          if (appSections.length === 0) {
            firstSection = true;
          }

          appSections.push({
            openFlters: [],
            title: section.title,
            active: firstSection,
            defaultActive: firstSection,
            pages,
            filters: _.cloneDeep(sectionFilters),
            defaultFilters: _.cloneDeep(sectionFilters),
          });
        }
      });

      console.log({
        ...defaultContext,
        sections: appSections,
        user: appUser,
        server: config.server,
        jaqlQueryUtil: sisenseAPI,
      });

      return {
        ...defaultContext,
        sections: appSections,
        user: appUser,
        server: config.server,
        jaqlQueryUtil: sisenseAPI,
      };
    })
    .catch((error: any) => {
      return null;
    });
}
