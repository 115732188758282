import {
  DashboardFilter,
  jaql,
  FilterOption,
  CascadingDashboardFilter,
  TextDashboardFilter,
  DateTimeDashboardFilter,
} from "../../components/EmbedSDK";
import  timeFrames from "../../hooks/DateTimeFilter/timeFrames.json"
export function parseFilters(filters: Array<any>): Array<DashboardFilter> {
  return filters.map((filter) => {
    let cascading = filter.isCascading;
    let disabled = filter.disabled;
    let jaql = filter.jaql;
    let levels: Array<jaql> = filter.levels;
    let value = "";
    console.log("Filter", filter);

    if (cascading) {
      return resetFilterJaql({
        cascading,
        disabled,
        levels,
        type: "cascading",
        name: levels.reduce((prev, current) => `${prev}${current.title}-`, ""),
      });
    } else {
      return resetFilterJaql({
        cascading,
        disabled,
        jaql,
        type: jaql.datatype,
        name: jaql.title,
      });
    }
  });
}
// Resets the filter jaql to a default state 
export function resetFilterJaql(
  filter:
    | CascadingDashboardFilter
    | DateTimeDashboardFilter
    | TextDashboardFilter
): DashboardFilter {
  switch (filter.type) {
    case "text":
      (filter as TextDashboardFilter).jaql!.filter = {
        all: true,
        explicit: false,
        multiSelection: true,
      };
      break;
    case "datetime":
      let defaultFrame = timeFrames[(filter as DateTimeDashboardFilter).jaql.level!][0];
      (filter as DateTimeDashboardFilter).jaql!.filter = defaultFrame.filter;
      break;
 
    
    case "cascading":
      for (let level of (filter as CascadingDashboardFilter).levels!) {
        level.filter = {
          all: true,
          explicit: false,
          multiSelection: true,
        };
      }
  }

  return filter;
}
