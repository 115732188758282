import { useState, useEffect, useCallback } from "react";
import {
  jaql,
  jaqlFilter,
  FilterOption,
  SelectionFormat,
  jaqlListFilter,
} from "../../components/EmbedSDK";
import { ApiResult } from "../JaqlQueryAPI";
import _ from "lodash";

type SimpleFilterState = {
  selectedDictionary: { [key: string]: FilterOption };
  excludedDictionary: { [key: string]: FilterOption };
  selectionFormat: SelectionFormat;
};

export default function useSimpleFilter(jaql: jaql, options: ApiResult) {
  const [filterJaql, setFilterJaql] = useState<jaql>({ ...jaql });
  // const [filterLevels, setFilterLevels] = useState<Array<jaql> | undefined >(levels);
  const [values, setValues] = useState<Array<FilterOption>>([]);
  const [draftState, setDraftState] = useState<SimpleFilterState>({
    selectedDictionary: {},
    excludedDictionary: {},
    selectionFormat: "Include All",
  });

  const [state, setState] = useState<SimpleFilterState>({
    selectedDictionary: {},
    excludedDictionary: {},
    selectionFormat: "Include All",
  });

  const [searchTerm, setSearchTerm] = useState("");

  // Set all selected once we have all the options
  // useEffect(() => {
  //   // if (options.status === "resolved") {
  //     let it = options.getValues();
  //     console.log("Options Changed:", it);

  //     // create a selected dictionary
  //     let selectedDict: { [key: string]: FilterOption } = {};
  //     it.forEach((it) => (selectedDict[it.data] = it));

  //     // Set Draft state
  //     setDraftState({
  //       ...draftState,
  //       selectedDictionary: { ...selectedDict },
  //       excludedDictionary: {},
  //       selectionFormat: "Include All",
  //     });

  //     // Set hook state
  //     setState({
  //       ...state,
  //       selectedDictionary: selectedDict,
  //       excludedDictionary: {},
  //       selectionFormat: "Include All",
  //     });
  //   // }
  //   console.log("OPTIONS:", jaql, options);
  // }, [options.status]);

  // useEffect(() => {
  //   console.log("OPTIONS STATUS CHANGED:", jaql, options);
  //   if (options.status === "resolved") {
  //     let it = options.getValues();
  //     console.log("NEW OPTIONS == CHANGED", it);

  //     // create a selected dictionary
  //     let selectedDict: { [key: string]: FilterOption } = {};
  //     it.forEach((it) => (selectedDict[it.data] = it));

  //     // Set Draft state
  //     setDraftState({
  //       selectedDictionary: { ...selectedDict },
  //       excludedDictionary: {},
  //       selectionFormat: "Include All",
  //     });

  //     // Set hook state
  //     setState({
  //       selectedDictionary: { ...selectedDict },
  //       excludedDictionary: {},
  //       selectionFormat: "Include All",
  //     });
  //   }
  // }, [options.status]);

  useEffect(() => {

    options.getValues().then((items) => {
      console.log("$$$ options.status changed => ", filterJaql, items);
      let filter = filterJaql.filter as jaqlListFilter;
      let selectedDict: { [key: string]: FilterOption } = {};
      let excludedDict: { [key: string]: FilterOption } = {};

      if (filter.all) {
        items.forEach((it) => (selectedDict[it.data] = it));
      }

      if (filter.exclude) {
        filter.exclude.members.forEach(
          (ex) => (excludedDict[ex] = { data: ex, text: ex })
        );
        items.forEach((it) =>
          !excludedDict[it.data] ? (selectedDict[it.data] = it) : null
        );
      }

      if (filter.members) {
        filter.members.forEach(
          (ex) => (selectedDict[ex] = { data: ex, text: ex })
        );
        items.forEach((it) =>
          !selectedDict[it.data] ? (excludedDict[it.data] = it) : null
        );
      }

      let sf = getSelectionFormat(
        Object.values(selectedDict),
        Object.values(excludedDict),
        items
      );

      // Set Draft state
      setDraftState({
        selectedDictionary: { ...selectedDict },
        excludedDictionary: { ...excludedDict },
        selectionFormat: sf,
      });

      // Set hook state
      setState({
        selectedDictionary: { ...selectedDict },
        excludedDictionary: { ...excludedDict },
        selectionFormat: sf,
      });
      setValues(items)
    });
  }, [filterJaql, options.query]);

  useEffect(() => {
    console.log("$$$ jaql changed");
    if (!_.isEqual(jaql, filterJaql)) {
      console.log("$$$ jaql is different");
      setFilterJaql(jaql);
    }
  }, [jaql]);

  // useEffect(()=> {
  //   console.log("$$$ levels changed");
  //   if (!_.isEqual(levels, filterLevels)) {
  //     console.log("$$$ levels is different");
  //     setFilterJaql({...filterJaql});
  //   }
  // },[levels])

  function getSelectionFormat(
    selected: Array<FilterOption>,
    excluded: Array<FilterOption>,
    items: Array<FilterOption>
  ): SelectionFormat {
    let sf: SelectionFormat = "Include All";

    if (selected.length === items.length) {
      sf = "Include All";
    }

    if (selected.length === 0) {
      sf = "None";
    }

    if (selected.length < items.length && selected.length > excluded.length) {
      sf = "All Exept";
    }

    if (excluded.length < items.length && selected.length <= excluded.length) {
      sf = "Include Only";
    }

    return sf;
  }

  const getFilterJaql = useCallback((): jaql => {
    let selected = Object.values(draftState.selectedDictionary);
    let excluded = Object.values(draftState.excludedDictionary);
    let filter: jaqlFilter;

    switch (draftState.selectionFormat) {
      case "All Exept":
        filter = {
          exclude: {
            members: excluded.map((it) => it.data),
          },
          multiSelection: true,
          explicit: false,
        };
        break;

      case "None":
        filter = {
          multiSelection: true,
          explicit: false,
          all: true,
        };
        break;
      case "Include Only":
        filter = {
          multiSelection: true,
          explicit: true,
          members: selected.map((it) => it.data),
        };
        break;
      case "Include All":
        filter = {
          multiSelection: true,
          explicit: false,
          all: true,
        };
        break;
    }

    return { ...filterJaql, filter };
  }, [draftState, filterJaql]);

  const toggleSelection = useCallback(
     (option: FilterOption) => {
      let alreadySelected = draftState.selectedDictionary[option.data];
      let alreadyExcluded = draftState.excludedDictionary[option.data];
      let selected = { ...draftState.selectedDictionary };
      let excluded = { ...draftState.excludedDictionary };

      if (alreadySelected) {
        delete selected[option.data];
        excluded[option.data] = option;
      }

      if (alreadyExcluded) {
        selected[option.data] = option;
        delete excluded[option.data];
      }
      let sf = getSelectionFormat(
        Object.values(selected),
        Object.values(excluded)!,
        values
      );
      setDraftState({
        selectedDictionary: selected,
        excludedDictionary: excluded,
        selectionFormat: sf,
      });
    },
    [draftState, values]
  );

  function toggleAll() {
    if (draftState.selectionFormat === "Include All") {
      setDraftState({
        selectionFormat: "None",
        excludedDictionary: { ...draftState.selectedDictionary },
        selectedDictionary: {},
      });
    } else {
      let selectedDict: { [key: string]: FilterOption } = {};
      values.forEach((it) => (selectedDict[it.data] = it));

      setDraftState({
        selectionFormat: "Include All",
        selectedDictionary: selectedDict,
        excludedDictionary: {},
      });
    }
  }

  const cleanFilter = useCallback(() => {
    setDraftState({ ...state });
    console.log("FILTER CLEARED - CHANGED");
  }, [state]);

  // const saveFilter = useCallback(() => {
  //   setState({ ...draftState });
  //   changeFilterJaql(draftState);
  // }, [draftState, changeFilterJaql]);

  // const resetFilter = useCallback(() => {
  //   let it = options.getValues();

  //   // create a selected dictionary
  //   let selectedDict: { [key: string]: FilterOption } = {};
  //   it.forEach((it) => (selectedDict[it.data] = it));

  //   // Set Draft state
  //   setDraftState({
  //     selectedDictionary: { ...selectedDict },
  //     excludedDictionary: {},
  //     selectionFormat: "Include All",
  //   });

  //   // Set hook state
  //   setState({
  //     selectedDictionary: { ...selectedDict },
  //     excludedDictionary: {},
  //     selectionFormat: "Include All",
  //   });
  // }, [options]);

  const isSelected = useCallback(
    (option: FilterOption) => {
      return Boolean(draftState.selectedDictionary[option.data]);
    },
    [draftState]
  );

  const filterItems = useCallback((searchTerm: string) => {
    setSearchTerm(searchTerm);
  }, []);

  const getItems = useCallback(() => {
    if (searchTerm !== "")
      return values.filter((item) =>
        item.text.toLowerCase().includes(searchTerm.toLowerCase())
      );
    else return values;
  }, [searchTerm, options]);

  const getSelectedItems = useCallback(() => {
    return Object.values(draftState.selectedDictionary);
  }, [draftState]);

  return {
    isSelected,
    cleanFilter,
    getFilterJaql,
    selectionFormat: draftState.selectionFormat,
    getItems,
    toggleSelection,
    toggleAll,
    filterItems,
    getSelectedItems,
  };
}
