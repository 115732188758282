import { DashboardSection } from "../../context";
import Sidebar from "./Sidebar"
type SidebarProps = {
  sections: Array<DashboardSection>;
  width: number;
  open: boolean;
  onTabClick: (sectionTitle: string) => void;
  loading:boolean
};

export type { SidebarProps };
export default Sidebar
