import { Grid, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Content from "../Content";
import Appbar from "../Appbar";
import Sidebar from "../Sidebar";
import FiltersSection from "../FiltersSection";
import DashboardsSection from "../DashboardsSection";
import { DashboardPage } from "../../context";
import WizeLoader from "../WizeLoader";
import SkeletonWrapper from "../SkeletonWrapper";
import { CompanyDashboardProps } from ".";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function CompanyDashboard({ loading }: CompanyDashboardProps) {
  const [state, dispatch] = useContext(AppContext);
  const { sections, user } = state;
  const activeSection = sections.find((s) => s.active)!;
  const drawerWidth = 272;
  const [sidebarOpen, setSidebarOpen] = useState(true);

  function handleTabClick(sectionTitle: string) {
    dispatch({
      type: "SET_ACTIVE_SECTION",
      payload: { sectionTitle: sectionTitle },
    });
  }

  const handlePageChange = useCallback(
    (page: DashboardPage) => {
      dispatch({
        type: "SET_ACTIVE_PAGE",
        payload: {
          sectionTitle: activeSection.title,
          page,
        },
      });
    },
    [activeSection] //activeSection.title
  );

  const handlePageLoad = useCallback(
    (dashboardOid: string) => {
      let pg = activeSection.pages.find((p) => p.dashboardOid === dashboardOid);
      dispatch({
        type: "UPDATE_DASHBOARD_PAGE",
        payload: {
          sectionTitle: activeSection.title,
          page: { ...pg!, loading: false },
        },
      });
    },
    [activeSection] // activeSection.pages
  );

  function handleDashboardLoad() {}

  console.log("ACTIVE SECTION", activeSection);
  return (
    <Box id="App" sx={{ display: "flex" }}>
      <Sidebar
        loading={loading}
        sections={sections}
        open={sidebarOpen}
        width={drawerWidth}
        onTabClick={handleTabClick}
      />
      <Content drawerWidth={drawerWidth} sidebarOpen={sidebarOpen}>
        {loading ? (
          <Grid container id="content" height="100vh">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              padding="24px 40px 24px 40px"
              height="112px"
            >
              <Grid
                item
                container
                sm={8}
                justifyContent="flex-start"
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <SkeletonWrapper
                    loading={loading}
                    variant="circular"
                    animation="wave"
                    width={36}
                    height={36}
                  ></SkeletonWrapper>
                </Grid>

                <Grid container item sm={6} justifyContent="stretch">
                  <SkeletonWrapper
                    width="inherit"
                    animation="wave"
                    loading={loading}
                  >
                    <h1>example</h1>
                  </SkeletonWrapper>
                </Grid>
              </Grid>
              <Grid
                item
                container
                sm={4}
                spacing={1}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <SkeletonWrapper
                    animation="wave"
                    loading={loading}
                    variant="circular"
                    width={36}
                    height={36}
                  ></SkeletonWrapper>
                </Grid>
                <Grid item>
                  <SkeletonWrapper
                    animation="wave"
                    loading={loading}
                    height={22}
                    width={120}
                  ></SkeletonWrapper>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item justifyItems="center">
              <WizeLoader />
            </Grid>
          </Grid>
        ) : (
          <>
            <Appbar
              user={user!}
              currentSection={activeSection}
              onToggle={() => setSidebarOpen(!sidebarOpen)}
              open={sidebarOpen}
            />

            <FiltersSection section={activeSection} />
            <Grid
              padding="0px 40px"
              sx={{ backgroundColor: "white" }}
              flexDirection={"column"}
            >
              <DashboardsSection
                currentSection = {activeSection}
                filters={activeSection.filters}
                pages={activeSection.pages}
                onPageChange={handlePageChange}
                onPageLoad={handlePageLoad}
              />
            </Grid>
          </>
        )}
      </Content>
    </Box>
  );
}
