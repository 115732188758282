import EmbedSDK from "./EmbedSDK";
type EmbedSdkProps = {
  onDashboardLoad: (dashboardOid: string) => void;
  visible: boolean;
  dashboardOid: string;
  serverUrl: string;
  onFiltersChange?: Function;
  filters?: Array<DashboardFilter>;
  settings?: {
    showLeftPane: boolean;
    showToolbar: boolean;
    showRightPane: boolean;
  };
  shouldRender: boolean;
  exportDashboard: { exportMode: ExportMode | null };
  loaderComponent?: Function;
  addOverlay: boolean
};

type SelectionFormat = "All Exept" | "Include Only" | "Include All" | "None";

export enum DateTimeLevel {
  // YEARS = "years",
  QUARTERS = "quarters",
  MONTHS = "months",
  WEEKS = "weeks",
  DAYS = "days",
}

const enum ExportMode {
  PDF = "pdf",
  PNG = "png",
}

const enum AppEventType {
  LOADED = "sisenseapploaded", // This is the only app event supported by EmbedSDK
}
const enum DashboardEventType {
  LOADED = "dashboardloaded",
  UNLOADED = "dashboardunloaded",
  FILTERS_CHANGED = "dashboardfilterschanged",
  REFRESHED = "dashboardrefreshed",
  STYLE_CHANGED = "dashboardstylechanged",
  WIDGET_ADDED = "dashboardwidgetadded",
  SIZECHANGED = "sizechanged",
}

type AppInfo = {
  version: string;
  isTrial: boolean;
};

type UserInfo = {
  oid: string;
  mail: string;
  username: string;
  firstName: string;
  lastName: string;
  role: { oid: string; name: string };
};

type Size = {
  width: number;
  height: number;
};

type UISettings = {
  showToolbar: boolean;
  showLeftPane: boolean;
  showRightPane: boolean;
};

type FrameState = {
  mode: number;
  dashboard: string;
  widget: string;
};

type FrameSizingInfo = {
  content: Size;
  leftpane: Size;
  midpane: Size;
  rightPane: Size;
  toolbar: Size;
};

type DashboardEventArgs = {
  dashboard: DashboardInfo;
  eventName: string;
};

type Datasource = {
  title: string; // Datasource title
  fullname: string; // Datasource fullname
  id: string; // Datasource id
  address: string; // Datasource address
  database: string; // Datasource database name
};

type DashboardInfo = {
  oid: string; // Dashboard OID
  title: string; // Dashboard title
  description: string; // Dashboard description
  instanceType: string; // "owner" or "user"
  owner: string; // Dashboard owner user OID
  userId: string; // Dashboard user OID (who's version of the dashboard is loaded)
  datasource: Datasource; // Dashboard's primary datasource
  filters: Array<DashboardFilter>; // Dashboard filters array
  created: Date; // Dashboard creation time
  lastUpdated: Date; // Dashboard last update
  lastUsed: Date; // Dashboard last used
  lastOpened: Date; // Dashboard last opened
};

type Dashboard = {
  filters: Array<DashboardFilter>;
  getCurrent: () => Promise<DashboardInfo>;
  on: (
    eventName: DashboardEventType,
    eventHandler: (args: DashboardEventArgs) => void
  ) => void;
  off: (
    eventName: DashboardEventType,
    eventHandler: (args: DashboardEventArgs) => void
  ) => void;
  applyFilters: (
    filters: Array<DashboardFilter>,
    persist: boolean
  ) => Promise<Array<DashboardFilter>>;
  removeFilters: (
    filters: Array<DashboardFilter>,
    persist: boolean
  ) => Promise<Array<DashboardFilter>>;
  export: (mode: ExportMode) => Promise<void>;
  open: (dashboardId: string, editMode?: boolean) => Promise<DashboardInfo>;
};

type SisenseFrame = {
  // Note: The properties 'id', 'url', 'element' will only be available once the render() method was called
  id: string;
  url: string;
  element: HTMLElement;
  dashboard: Dashboard;
  show: Function;
  hide: Function;
  app: any;
  render: (container?: HTMLElement | null, hidden?: boolean) => Promise<void>;
  getSize: () => Promise<FrameSizingInfo>;
  getSettings: () => UISettings;
  updateSettings: (settings: UISettings) => Promise<void>;
  getState: () => FrameState;
  on: (eventName: AppEventType, eventHandler: Function) => void;
  off: (eventName: AppEventType, eventHandler: Function) => void;
  getInfo: () => Promise<AppInfo>;
  getUser: () => Promise<UserInfo>;
};

type FilterOption = {
  data: string;
  text: string;
  selected?: boolean;
};

// type DashboardFilter = {
//   type: "text" | "datetime" | "cascading";
//   disabled: Boolean;
//   cascading: Boolean;
//   levels?: Array<jaql>;
//   jaql?: jaql;
//   options: Array<FilterOption>;
//   value: Array<FilterOption> | String;
//   name: string;
// };

interface DashboardFilter {
  type: "text" | "datetime" | "cascading";
  disabled: Boolean;
  cascading: Boolean;
  // levels?: Array<jaql>;
  // jaql?: jaql;
  name: string;
}

interface CascadingDashboardFilter extends DashboardFilter {
  levels: Array<jaql>;
}

interface TextDashboardFilter extends DashboardFilter {
  jaql: jaql;
}

interface DateTimeDashboardFilter extends DashboardFilter {
  jaql: jaql;
}

type jaqlFilter = jaqlListFilter | jaqlTimeFrameFilter;

type jaqlListFilter = {
  all?: boolean;
  explicit: boolean;
  multiSelection: boolean;
  members?: Array<string>;
  exclude?: { members: Array<string> };
};

type jaqlTimeFrameFilter = {
  next?: {
    count: number;
    offset: number;
  };
  last?: {
    count: number;
    offset: number;
  };
};

type jaql = {
  title: string;
  column: String;
  datasource: Datasource;
  datatype: String;
  dim: String;
  table: String;
  filter: jaqlFilter;
  options: Array<FilterOption>;
  level?: DateTimeLevel;
  panel?: string;
};

export type {
  EmbedSdkProps,
  Size,
  FrameSizingInfo,
  Dashboard,
  DashboardEventArgs,
  DashboardInfo,
  Datasource,
  SisenseFrame,
  FilterOption,
  SelectionFormat,
  DashboardFilter,
  jaql,
  jaqlFilter,
  TextDashboardFilter,
  DateTimeDashboardFilter,
  CascadingDashboardFilter,
  jaqlTimeFrameFilter,
  jaqlListFilter,
};

export { DashboardEventType, ExportMode };

export default EmbedSDK;
